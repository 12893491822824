<template>
  <div>
      <h5>Are you sure you want to complete this action ?</h5>
  </div>
</template>

<script>
  export default {
    name: 'ConfirmActionDialog',
  }
</script>
